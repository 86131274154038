/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { z, ZodError } from 'zod'
import { Form } from '@unform/web'
import type { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import { InputMask } from 'src/components/common/Input'
import { Modal } from 'src/components/common/Modal'
import { Spinner } from 'src/components/common/Spinner'
import Icon from 'src/components/ui/Icon'
import { useKitLook } from 'src/components/sections/ProductDetails/KitLook/hook/KitLookContext'
import { useShippingKitLook } from 'src/components/sections/ProductDetails/KitLook/hook/KitLookShippingContext'
import { CepContext } from 'src/components/common/CepComponent/hooks/CepInformationContext'
import { formatterPrice } from 'src/utils/formatterPrice'
import { InformationIcon } from 'src/components/Icons/InformationIcon'
import { ModalRecommendations } from 'src/components/product/SelectShipping/ModalRecommendations'
import type { ListShipping } from 'src/components/product-page/context/types/index.type'

type IFormValues = {
  cep: string
}

type Props = {
  products: Array<{
    productId: string
    itemId: string
    quantity: string
    seller: string
  }>
  sellerName: string | undefined
}

type ReferenceProduct = {
  id: string
  name: string
  price: number
  oldPrice: number
  url: string
  images: {
    default: string
  }
  installment: {
    count: number
    price: string
  }
  available: string
}

type SimilarProductsProps = {
  displays: Array<{
    references: ReferenceProduct[]
    recommendations: ReferenceProduct[]
  }>
  context: string
}
const schema = z.string().regex(/^[0-9]{2}[0-9]{3}-[0-9]{3}$/, 'CEP invalido')

const formatEstimate = (estimate: any) => {
  switch (true) {
    case /bd/.test(estimate):
      return estimate.replace('bd', ' dias úteis')

    case /d/.test(estimate):
      return estimate.replace('d', ' dias')

    case /h/.test(estimate):
      return estimate.replace('h', ' horas')

    case /m/.test(estimate):
      return estimate.replace('m', ' minutos')

    default:
      return `${estimate} dias úteis`
  }
}

const filterBestShipping = (listShipping: ListShipping[]) => {
  const formatPriceItemsDelivery = listShipping?.[0]?.map((item) => {
    if (item.price !== 'Gratis') {
      const priceFormat = item.price
        .replace(/[~`!@#$%^&*()+={}[\];:'"<>/\\?-_]/gi, '')
        .trim()
        .replace(',', '.')

      return { ...item, price: parseFloat(priceFormat), isPickup: false }
    }

    return { ...item, price: 0, isPickup: false }
  })

  const itemsDelivery = formatPriceItemsDelivery.filter(
    (item: any) =>
      !item.id.includes('Loja') &&
      !item.id.includes('loja') &&
      !item.id.includes('Retirar') &&
      !item.id.includes('retirar') &&
      !item.id.includes('Retirada') &&
      !item.id.includes('retirada') &&
      !item.id.includes('Retire') &&
      !item.id.includes('retire') &&
      !item.id.includes('Clique') &&
      !item.id.includes('clique')
  )

  const itemsPickupPoint = formatPriceItemsDelivery.filter(
    (item: any) =>
      item.id.includes('Loja') ||
      item.id.includes('loja') ||
      item.id.includes('Retirar') ||
      item.id.includes('retirar') ||
      item.id.includes('Retirada') ||
      item.id.includes('retirada') ||
      item.id.includes('Retire') ||
      item.id.includes('retire') ||
      item.id.includes('Clique') ||
      item.id.includes('clique')
  )

  function transformEstimateInSeconds(time: string) {
    if (time.match(/bd/)) {
      const days = Number(time.replace(/[^0-9]/g, ''))

      return days * 24 * 60 * 60
    }

    if (time.match(/m/)) {
      return Number(time.replace(/[^0-9]/g, '')) * 60
    }

    return Number(time.replace(/[^0-9]/g, ''))
  }

  itemsDelivery.sort(
    (a, b) =>
      transformEstimateInSeconds(String(a.shippingEstimate)) -
      transformEstimateInSeconds(String(b.shippingEstimate))
  )
  const [fastestItem] = itemsDelivery

  itemsDelivery.sort((a, b) => a.price - b.price)
  const [cheaperItem] = itemsDelivery

  itemsPickupPoint.sort((a, b) => a.price - b.price)
  const [bestWithdrawalOption] = itemsPickupPoint

  if (bestWithdrawalOption?.id) {
    bestWithdrawalOption.isPickup = true
  }

  if (fastestItem?.id === cheaperItem?.id) {
    return [fastestItem, bestWithdrawalOption].filter((item) => item)
  }

  return [fastestItem, cheaperItem, bestWithdrawalOption].filter((item) => item)
}

export function SelectShippingKitLook({ products }: Props) {
  const formRef = useRef<FormHandles>(null)

  const {
    clearShippings,
    getShipping,
    listShipping,
    address,
    validating,
    shippingData,
    modalShippingOpen,
    setModalShippingOpen,
  } = useShippingKitLook()

  const { setNotification, changeValidBuySku } = useKitLook()
  const { cepStorage, setCepStorage } = useContext(CepContext)

  const [isShelfLinxOpen, setIsShelfLinxOpen] = useState(false)
  const [isError, setIsError] = useState(false)
  const [similarProducts, setSimilarProducts] = useState<
    ReferenceProduct[] | undefined
  >(undefined)

  const sendEventDataLayer = (eventAction: string, userEvent: boolean) => {
    const obj = {
      event: 'ga_custom_event',
      event_category: 'vitrine_geolocalizacao_pdp',
      event_action: eventAction,
      event_is_user_interaction: userEvent,
    }

    if (typeof window === 'undefined') {
      return
    }

    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push(obj)
  }

  const fetchShippingData = async (cepSubmitted: string) => {
    const res = await axios.post(`/api/shipping`, {
      items: [],
      postalCode: cepSubmitted,
      country: 'BRA',
    })

    return res.data.AddressByPostalCode
  }

  const validateCep = async (cepSubmitted: string) => {
    await schema.parseAsync(cepSubmitted)
  }

  const isValidPostalCode = (dataPostalCode: any) => {
    return (
      dataPostalCode?.geoCoordinates?.length > 0 && dataPostalCode.state !== ''
    )
  }

  const handleInvalidPostalCode = () => {
    setIsError(true)
    formRef.current?.setFieldError(
      'cep',
      'Número de CEP inválido. Utilize outro CEP.'
    )
  }

  const processShippingData = async (cepSubmitted: string) => {
    const postalCodeValue = cepSubmitted.split('-').join('')
    const shippingInfo = await getShipping(postalCodeValue, products)

    const selectedSeller = shippingInfo.find(
      (item) => item.items?.[0]?.seller === products[0]?.seller
    )

    const allItemsUnavailable = selectedSeller?.items?.every(
      (item) => item.availability !== 'available'
    )

    if (allItemsUnavailable) {
      setNotification(null)
    }

    shippingInfo[0]?.items.forEach((item) => {
      if (item.availability !== 'available') {
        changeValidBuySku(item.id)
      }
    })

    return allItemsUnavailable ? null : shippingInfo
  }

  const handleShippingSuccess = async (
    processedShippingData: ShippingItem[] | null,
    cepSubmitted: string
  ) => {
    formRef.current?.setFieldError('cep', '')
    localStorage.setItem('cep', cepSubmitted)
    localStorage.setItem('prodId', products[0]?.productId)

    setIsError(false)
    setCepStorage(cepSubmitted)
    setModalShippingOpen(false)

    const selectedSeller = processedShippingData?.find(
      (item) => item.items?.[0]?.seller === products[0]?.seller
    )

    if (selectedSeller) {
      return
    }

    const recommendations = await handleLinxRecommendations(
      products[0].productId
    )

    if (!recommendations) {
      return
    }

    setSimilarProducts(recommendations.displays[0]?.recommendations)
    setIsShelfLinxOpen(true)
    sendEventDataLayer('Vitrine exibida', false)
  }

  const handleShippingError = (err: any) => {
    setIsError(true)
    const errorMessage =
      err instanceof ZodError
        ? err.errors[0].message
        : 'Número de CEP inválido. Utilize outro CEP.'

    formRef.current?.setFieldError('cep', errorMessage)
  }

  const handleSubmit: SubmitHandler<IFormValues> = async ({ cep }) => {
    const cepSubmitted = cep !== '' ? cep : String(cepStorage)

    try {
      const dataPostalCode = await fetchShippingData(cepSubmitted)

      if (!isValidPostalCode(dataPostalCode)) {
        handleInvalidPostalCode()

        return
      }

      await validateCep(cepSubmitted)

      const processedShippingData = await processShippingData(cepSubmitted)

      if (!shippingData) {
        return
      }

      handleShippingSuccess(processedShippingData, cepSubmitted)
    } catch (err) {
      handleShippingError(err)
    }
  }

  useEffect(() => {
    if (
      cepStorage !== '' &&
      cepStorage !== null &&
      products?.length > 0 &&
      formRef.current
    ) {
      handleSubmit({ cep: cepStorage }, formRef.current)
      setCepStorage(cepStorage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cepStorage, products])

  const modalCep = () => {
    clearShippings()
    setModalShippingOpen(true)
  }

  const filterShippingList = useMemo(() => {
    if (!listShipping?.[0]) {
      return null
    }

    return filterBestShipping(listShipping)
  }, [listShipping])

  const inputBorderColor = isError ? 'border-danger' : 'border-blue'
  const validateState = validating ? (
    <Spinner color="#FFF" />
  ) : (
    'Consultar frete'
  )

  const shippingSellerSelect = useMemo(() => {
    return shippingData?.find(
      (item) => item.items?.[0]?.seller === products[0]?.seller
    )
  }, [shippingData, products])

  const handleLinxRecommendations = async (
    prodId: string
  ): Promise<SimilarProductsProps> => {
    const { data }: { data: SimilarProductsProps } = await axios.get(
      'https://recs.chaordicsystems.com/v0/products/recommendations',
      {
        params: {
          apiKey: 'decathlon',
          secretKey: '48oy25lKgfRXofI0jzljeg==',
          type: 'Similar',
          productId: prodId,
        },
      }
    )

    return data
  }

  return (
    <>
      <div className="mt-6">
        <p className="text-sm font-roboto text-deepGray font-bold">
          Opções de frete e retirada:
        </p>

        <div className="flex flex-col md:flex-row items-start gap-4 md:gap-0 md:items-center justify-between mt-4 p-4 bg-neutral02 rounded-lg">
          {address ? (
            <>
              <p className="text-sm font-roboto text-deepGray">
                {address.city} / {address.state} <br />
                {cepStorage}
              </p>
              <button
                className="text-sm font-roboto text-primaryDark font-bold"
                onClick={modalCep}
              >
                Alterar CEP
              </button>
            </>
          ) : (
            <>
              <p className="text-sm font-roboto text-deepGray">
                Consulte os prazos de entrega e retirada
              </p>
              <button
                className="text-sm font-roboto text-primaryDark font-bold"
                onClick={modalCep}
              >
                Adicionar CEP
              </button>
            </>
          )}
        </div>

        {shippingSellerSelect &&
          shippingSellerSelect.items?.[0]?.availability !== 'available' && (
            <div className="flex items-center mt-4">
              <InformationIcon color="#EE000C" />
              <p className="ml-[4px] font-roboto text-[#EE000C]">
                Produto indisponível para o CEP.
              </p>
            </div>
          )}

        {shippingSellerSelect?.items?.[0]?.availability === 'available' &&
          filterShippingList !== null && (
            <>
              <p className="mt-4 text-sm font-roboto text-deepGray font-bold">
                Produtos Decathlon:
              </p>
              <ul className="mt-4">
                {filterShippingList?.map((shippingItem, idx) => (
                  <li
                    className="flex items-center justify-between mb-4"
                    key={`${shippingItem?.id}-${idx}`}
                  >
                    <p className="text-deepGray font-roboto text-base">
                      {shippingItem?.isPickup
                        ? 'Retire na loja em até'
                        : 'Receba em até'}
                      {'  '}
                      {formatEstimate(
                        shippingItem?.shippingEstimate ?? ''
                      )}{' '}
                    </p>

                    <span className="text-deepGray font-roboto">
                      {Number(shippingItem?.price) > 0 ? (
                        formatterPrice(Number(shippingItem?.price))
                      ) : (
                        <span className="text-[#017253] bg-[#E6F9F3] text-sm font-roboto px-2 py-0.5 rounded">
                          Grátis
                        </span>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
            </>
          )}
      </div>

      <Modal
        onClose={() => setModalShippingOpen(false)}
        isOpen={modalShippingOpen}
        className="rounded-lg w-[90%] md:w-full max-w-lg pp:max-h-[95vh] pp:min-h-fit"
      >
        <button
          onClick={() => setModalShippingOpen(false)}
          className="absolute top-5 right-5"
        >
          <Icon name="Close" width={24} height={24} fill="#007cb9" />
        </button>
        <div className="py-8 px-6 md:px-8 rounded h-full">
          <div className="flex items-center justify-between">
            <p className="font-bold text-xl font-roboto text-neutral10 ">
              Consulte frete e retirada
            </p>
          </div>

          <p className="text-sm font-roboto text-deepGray mt-2">
            A Decathlon oferece diferentes opções de frete e retirada em loja.{' '}
            <br />
            Adicione seu CEP para ver a disponibilidade em sua região.
          </p>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            className="flex flex-col md:flex-row items-start gap-x-2 mt-6"
          >
            <InputMask
              name="cep"
              mask="99999-999"
              className={`h-[48px] w-full border ${inputBorderColor} rounded`}
              classNameLabel="flex-1 w-full md:w-auto"
              placeholder={cepStorage ?? `Informe seu cep`}
            />

            <button className="mt-2 md:mt-0 w-full md:max-w-[150px] h-[48px] bg-[#007DBC] text-white font-roboto font-bold rounded flex items-center justify-center">
              {validateState}
            </button>
          </Form>
          {shippingSellerSelect &&
            shippingSellerSelect.items?.[0].availability !== 'available' && (
              <div className="flex items-center mt-4">
                <InformationIcon color="#EE000C" />
                <p className="ml-[4px] font-roboto text-[#EE000C]">
                  Produto indisponível para o CEP.
                </p>
              </div>
            )}

          <div className="flex items-start justify-center mt-3 gap-2 p-4 bg-[#F7F8F9]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="12"
              viewBox="0 0 14 12"
              fill="none"
              className="!w-[14px] !h-[12px] mt-1"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.9322 0.228647C6.19845 0.0787484 6.49883 0 6.80437 0C7.10992 0 7.4103 0.0787484 7.67655 0.228647C7.94279 0.378545 8.16591 0.594534 8.32437 0.855772L8.32629 0.858938L13.0318 8.7145L13.0373 8.72371C13.1925 8.99253 13.2746 9.29731 13.2755 9.60772C13.2764 9.91814 13.1959 10.2234 13.0422 10.4931C12.8885 10.7627 12.6668 10.9875 12.3993 11.1449C12.1318 11.3023 11.8277 11.387 11.5173 11.3904L11.5099 11.3905L2.09149 11.3904C1.78109 11.387 1.47699 11.3023 1.20945 11.1449C0.941913 10.9875 0.720257 10.7627 0.566537 10.4931C0.412816 10.2234 0.33239 9.91814 0.333259 9.60772C0.334128 9.29731 0.416263 8.99253 0.571491 8.72371L0.576906 8.7145L5.28246 0.858944L5.13771 1.05709L5.28437 0.855772C5.44284 0.594534 5.66595 0.378545 5.9322 0.228647ZM6.42513 1.54604L1.72409 9.39405C1.68661 9.46037 1.6668 9.53524 1.66659 9.61146C1.66637 9.68906 1.68648 9.76537 1.72491 9.83279C1.76334 9.90021 1.81875 9.9564 1.88564 9.99575C1.95171 10.0346 2.0267 10.0558 2.10332 10.0571H11.5054C11.582 10.0558 11.657 10.0346 11.7231 9.99575C11.79 9.9564 11.8454 9.90021 11.8838 9.83279C11.9223 9.76537 11.9424 9.68906 11.9422 9.61146C11.9419 9.53523 11.9221 9.46037 11.8847 9.39405L7.18437 1.54728L7.18362 1.54604C7.14405 1.48128 7.08856 1.42773 7.02242 1.39049C6.95586 1.35302 6.88076 1.33333 6.80437 1.33333C6.72799 1.33333 6.65289 1.35302 6.58633 1.39049C6.52019 1.42773 6.4647 1.48128 6.42513 1.54604Z"
                fill="#4E5D6B"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.80437 3.39041C7.17256 3.39041 7.47104 3.68889 7.47104 4.05708V6.2793C7.47104 6.64749 7.17256 6.94597 6.80437 6.94597C6.43618 6.94597 6.13771 6.64749 6.13771 6.2793V4.05708C6.13771 3.68889 6.43618 3.39041 6.80437 3.39041Z"
                fill="#4E5D6B"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.13771 8.50152C6.13771 8.13333 6.43618 7.83486 6.80437 7.83486H6.80993C7.17812 7.83486 7.4766 8.13333 7.4766 8.50152C7.4766 8.86971 7.17812 9.16819 6.80993 9.16819H6.80437C6.43618 9.16819 6.13771 8.86971 6.13771 8.50152Z"
                fill="#4E5D6B"
              />
            </svg>

            <p className="max-w-[400px] flex-1 text-xs font-roboto text-deepGray">
              Atenção! O preço das entregas dos produtos Decathlon podem variar
              de acordo com a região em que você está!
            </p>
          </div>
        </div>
      </Modal>
      {similarProducts && (
        <ModalRecommendations
          sendEventDataLayer={sendEventDataLayer}
          isOpen={isShelfLinxOpen}
          setIsShelfLinxOpen={setIsShelfLinxOpen}
          setModalShippingOpen={setModalShippingOpen}
          similarProducts={similarProducts}
        />
      )}
    </>
  )
}
